import { ethers } from "ethers";
import { USDT, cryptoInvest, cryptoInvestABI } from "../config";
import Web3 from "web3";
import USDTABI from "../USDTABI.json";
import Swal from "sweetalert2";

let web3 = new Web3(window.ethereum);
const data = {
  project: {
    name: "",
    allocation: 0,
    raised: 0,
    price: 0,
    vesting: "",
    startDate: 0,
    endDate: 0,
    maxInvestment: 0,
  },
  projectdata: {
    projectId: "",
    orbital7: "",
    feature: [],
    advantage: [],
    listing: [],
    streams: [],
  },
};

// export async function getCryptoInvestData(setState) {
//   try {
//     console.log("coming");
//     let contract = new web3.eth.Contract(cryptoInvestABI, cryptoInvest);
//     console.log("coming contract", contract);

//     const project_count = await contract?.methods?.pCount().call();
//     console.log(project_count, "project count");

//     const active_project = await contract?.methods
//       ?.Projects(project_count - 1)
//       .call();

//     console.log("active_project : ", active_project);

//     const active_project_data = await contract?.methods
//       ?.getProjectData(project_count - 1)
//       .call();

//     console.log("active_project_data : ", active_project_data);

//     const activepObj = { ...data.project };
//     const activepdataObj = { ...data.projectdata };
//     Object.keys(data.project).forEach((item, i) => {
//       activepObj[item] = active_project[item];
//     });
//     Object.keys(data.projectdata).forEach((item, i) => {
//       activepdataObj[item] = active_project_data[item];
//     });
//     const fobj = {
//       project: { ...activepObj },
//       projectdata: { ...activepdataObj },
//     };
//     // console.log(fobj, "fobj");
//     setState(fobj);
//   } catch (e) {
//     console.log("Error in getCryptoInvestData", e);
//     setState(data);
//   }
// }

// export async function getCryptoInvestData(address, setState) {
//   try {
//     if (!address) {
//       console.log("No address provided in getCryptoInvestData");
//       return;
//     }
//     console.log("getCryptoInvestData called with address: " + address);
//     let contract = new web3.eth.Contract(cryptoInvestABI, cryptoInvest);
//     const project_count = await contract.methods.pCount().call();
//     const active_project = await contract.methods
//       .Projects(project_count - 1)
//       .call();

//     console.log(
//       "getCryptoInvestData Called and active_project : ",
//       active_project
//     );
//     const active_project_data = await contract.methods
//       .getProjectData(project_count - 1)
//       .call();
//     console.log(
//       "getCryptoInvestData Called and active_project_data : ",
//       active_project_data
//     );

//     const activepObj = { ...data.project };
//     const activepdataObj = { ...data.projectdata };
//     Object.keys(data.project).forEach((item, i) => {
//       activepObj[item] = active_project[item];
//     });
//     Object.keys(data.projectdata).forEach((item, i) => {
//       activepdataObj[item] = active_project_data[item];
//     });
//     const fobj = {
//       project: { ...activepObj },
//       projectdata: { ...activepdataObj },
//     };
//     setState(fobj);
//   } catch (e) {
//     Swal.fire({
//       position: "center",
//       icon: "error",
//       title: "Unable to fetch data please reload the page",
//       showConfirmButton: false,
//       timer: 1500,
//     });

//     console.error("Error in getCryptoInvestData", e.message);
//     setState(data);
//   }
// }

export async function getCryptoInvestData2(address, setState) {
  try {
    if (!address) {
      console.log("No address provided in getCryptoInvestData");
      return;
    }
    console.log("getCryptoInvestData called with address: " + address);
    let contract = new web3.eth.Contract(cryptoInvestABI, cryptoInvest);
    const project_count = parseInt(await contract?.methods?.pCount().call());
    const active_project = await contract.methods
      .Projects(project_count - 1)
      .call();

    console.log(
      "getCryptoInvestData Called and active_project : ",
      active_project
    );
    const active_project_data = await contract.methods
      .getProjectData(project_count - 1)
      .call();
    console.log(
      "getCryptoInvestData Called and active_project_data : ",
      active_project_data
    );

    const activepObj = { ...data.project };
    const activepdataObj = { ...data.projectdata };
    Object.keys(data.project).forEach((item, i) => {
      activepObj[item] = active_project[item];
    });
    Object.keys(data.projectdata).forEach((item, i) => {
      activepdataObj[item] = active_project_data[item];
    });
    const fobj = {
      project: { ...activepObj },
      projectdata: { ...activepdataObj },
    };
    return fobj;
  } catch (e) {
    Swal.fire({
      position: "center",
      icon: "error",
      title: "Unable to fetch data please reload the page",
      showConfirmButton: false,
      timer: 1500,
    });

    console.error("Error in getCryptoInvestData", e.message);
    return data;
  }
}

// let username = "";
// let email = "";

async function OpenFormModule() {
  const { value: formValues } = await Swal.fire({
    // title:
    //   "Welcome to Launchpad Deals. Please write below your details below to have access to investment opportunities.",
    html:
      "<h1>Welcome to Launchpad Deals. Please write below your details below to have access to investment opportunities.</h1>" +
      '<input id="username" class="swal2-input" style="margin-bottom: 10px;" placeholder="Username">' +
      '<input id="email" class="swal2-input" style="margin-bottom: 10px;" placeholder="Email">',
    focusConfirm: false,
    allowOutsideClick: false,
    confirmButtonText: "Submit",
    preConfirm: () => {
      return {
        username: document.getElementById("username").value,
        email: document.getElementById("email").value,
      };
    },
  });

  if (formValues) {
    return formValues;
  }
}

// useEffect(() => {
//   const timer = setTimeout(() => {
//     console.log("isUserExist : ", isUserExist);
//     if (!isUserExist) {
//       OpenFormModule();
//     }
//   }, 4000); // 3 seconds delay

//   return () => clearTimeout(timer); // Clear the timeout if the component unmounts
// }, [address, isUserExist]);

export async function investUSDT(
  address,
  amount,
  setButtonText,
  setInputAmount
) {
  try {
    console.log("coming");

    let isUserExist = await isUserInvested(address);
    let moduleInputs = {
      username: "",
      email: "",
    };

    if (!isUserExist) {
      moduleInputs = await OpenFormModule();
    }

    console.log("moduleInputs : ", moduleInputs);

    if (amount === "" || amount == undefined || amount == 0) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Invalid amount or zero amount",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    if (address) {
      setButtonText("Investing...");
      let contract = new web3.eth.Contract(cryptoInvestABI, cryptoInvest);
      const project_count = await contract?.methods?.pCount().call();
      const amt = Number(amount) * 1e18;
      const famt = amt.toLocaleString("fullwide", { useGrouping: false });

      const invest_amount = famt;
      console.log(invest_amount, amount);
      let gasPrice = await web3.eth.getGasPrice();
      let gas = await contract?.methods
        ?.Invest(
          project_count - 1,
          invest_amount,
          moduleInputs.username,
          moduleInputs.email
        )
        .estimateGas({ from: address, value: 0, gasPrice: gasPrice });
      const result = await contract?.methods
        ?.Invest(
          project_count - 1,
          invest_amount,
          moduleInputs.username,
          moduleInputs.email
        )
        .send({ from: address, value: 0, gas: 0, gasPrice: gasPrice });
      console.log(result, "reslut");
      if (result) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Successfully Invested in launchpad",
          showConfirmButton: false,
          timer: 1500,
        });

        setButtonText("Invest Successful");
        setTimeout(() => {
          setButtonText("Invest Now");
        }, 2500);
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Invest Failed",
          showConfirmButton: false,
          timer: 1500,
        });
        setButtonText("Invest Failed");
        setTimeout(() => {
          setButtonText("Invest Now");
        }, 1000);
      }
      setInputAmount("");
    } else {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Please connect with metamask wallet",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  } catch (e) {
    setButtonText("Invest Now");
    setInputAmount("");
    console.log("Error in getCryptoInvestData", e);
    alert(e.message);
  }
}

export async function approveToken(
  setLoader,
  setApproveTxDone,
  address,
  setButtonText
) {
  try {
    if (!address) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Please connect wallet to proceed",
        showConfirmButton: false,
        timer: 1500,
      });

      return;
    }

    setButtonText("Approving...");
    let USDTToken = new web3.eth.Contract(USDTABI, USDT);

    setLoader(true);
    const approveAmount = "12123223132323233213232323323232312321323";
    const tx = await USDTToken.methods.approve(cryptoInvest, approveAmount);

    let encoded_tx = tx.encodeABI();

    let gasPrice = await web3.eth.getGasPrice();

    let gasLimit = await web3.eth.estimateGas({
      gasPrice: web3.utils.toHex(gasPrice),
      to: USDT,
      from: address,
      data: encoded_tx,
    });

    let trx = await web3.eth.sendTransaction({
      gasPrice: web3.utils.toHex(gasPrice),
      gas: web3.utils.toHex(gasLimit),
      to: USDT,
      from: address,
      data: encoded_tx,
    });

    if (trx.transactionHash) {
      console.log(trx);
      setLoader(false);
      setApproveTxDone(true);
      setButtonText("Approved");

      Swal.fire({
        position: "center",
        icon: "success",
        title: "Approved successfully",
        showConfirmButton: false,
        timer: 1500,
      });

      setTimeout(() => {
        setButtonText("Invest Now");
      }, 1000);
    } else {
      setButtonText("Not Approved");
      setTimeout(() => {
        setButtonText("Invest Now");
      }, 1000);
    }

    setLoader(false);
  } catch (error) {
    setLoader(false);
    let err = error.data ? error.data.message : error.message;
    alert(err);
    console.log(err);
    setButtonText("Not Approved");
    setTimeout(() => {
      setButtonText("Invest Now");
    }, 1000);
  }
}

export async function checkAllowance(address, setState, amount) {
  try {
    if (!address) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Please connect wallet to proceed",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    let USDTToken = new web3.eth.Contract(USDTABI, USDT);

    const result = await USDTToken.methods
      .allowance(address, cryptoInvest)
      .call();
    console.log(amount, result, "result");
    if (Number(result) < Number(amount)) {
      setState(false);
    } else {
      setState(true);
    }
  } catch (error) {
    setState(false);
    let err = error.data ? error.data.message : error.message;
    alert(err);
    console.log(err, "alloance error");
  }
}

export async function getUserHistory(address, setState) {
  try {
    if (!address) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Please connect wallet to proceed",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    let contract = new web3.eth.Contract(cryptoInvestABI, cryptoInvest);

    const userHistory = await contract.methods.getUserHistory(address).call();
    console.log("userHistory from contract called : ", userHistory);

    setState(userHistory);
  } catch (error) {
    setState(false);
    let err = error.data ? error.data.message : error.message;
    alert(err);
    console.log(err, "alloance error");
  }
}

// getFirstTransaction

export async function isUserInvested(address) {
  try {
    if (!address) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Please connect wallet to proceed",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }

    let contract = new web3.eth.Contract(cryptoInvestABI, cryptoInvest);
    const isInvested = await contract.methods.investors(address).call();
    console.log("Is user Invested :", isInvested);

    return isInvested;
  } catch (error) {
    console.error("Error fetching first transaction:", error);
    Swal.fire({
      position: "center",
      icon: "error",
      title: "Failed to fetch first transaction",
      text: error.message,
    });
  }
}
