import React, { useEffect, useState } from "react";
import Hero from "./Hero";
import Explained from "./Explained";
import { getCryptoInvestData2 } from "../helpers/contractHelpers";
import { useAccount } from "wagmi";

const Home = () => {
  const { address } = useAccount();

  const [projectData, setProjectData] = useState({
    project: {
      name: "",
      allocation: 0,
      raised: 0,
      price: 0,
      vesting: "",
      startDate: 0,
      endDate: 0,
      maxInvestment: 0,
    },
    projectdata: {
      projectId: "",
      orbital7: "",
      feature: [],
      advantage: [],
      listing: [],
      streams: [],
    },
  });

  useEffect(() => {
    console.log("isConnected and address : ", address);
    const fetchData = async (address) => {
      let output = await getCryptoInvestData2(address);
      console.log("output : ", output);
      setProjectData(output);
    };

    if (address) {
      fetchData(address);
    }
  }, [address]);

  console.log("projectData Home : ", projectData);

  return (
    <>
      <Hero projectData={projectData} />
      <Explained projectData={projectData} />

      {/* <Hero />
      <Explained /> */}
    </>
  );
};

export default Home;
