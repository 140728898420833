import React, { useEffect, useState } from "react";
import logo from "../images/logo-sm.png";
import { USDT } from "../config";
import { useAccount } from "wagmi";
import { ethers } from "ethers";
import Web3 from "web3";
import USDTABI from "../USDTABI.json";

import {
  approveToken,
  checkAllowance,
  getUserHistory,
  investUSDT,
  isUserInvested,
} from "../helpers/contractHelpers";
import Swal from "sweetalert2";

const Hero = ({ projectData }) => {
  let web3 = new Web3(window.ethereum);
  const { address } = useAccount();

  const [loader, setLoader] = useState(false);
  const [isallowed, setisallowed] = useState(false);
  const [approveTxDone, setApproveTxDone] = useState();
  const [inputAmount, setInputAmount] = useState(0);
  const [tokenAmount, setTokenAmount] = useState(0);
  const [usdtBalance, setUSDTBalance] = useState();
  const [isUserExist, setUserExist] = useState(true);
  // const [username, setUsername] = useState("");
  // const [email, setEmail] = useState("");
  const [CryptoAddress, setCryptoAddress] = useState(
    "0xEeeeeEeEeeEeEeEeeEEEeeEeeeEEeE"
  );

  // const [project, setProject] = useState({
  //   project: {
  //     name: "",
  //     allocation: 0,
  //     raised: 0,
  //     price: 0,
  //     vesting: "",
  //     startDate: 0,
  //     endDate: 0,
  //     maxInvestment: 0,
  //   },
  //   projectdata: {
  //     projectId: "",
  //     orbital7: "",
  //     feature: [],
  //     advantage: [],
  //     listing: [],
  //     streams: [],
  //   },
  // });

  // async function OpenFormModule() {
  //   await Swal.fire({
  //     title: "Enter Username & Email Address",
  //     html:
  //       '<input id="username" class="swal2-input" style="margin-bottom: 10px;" placeholder="Username">' +
  //       '<input id="email" class="swal2-input" style="margin-bottom: 10px;" placeholder="Email">',
  //     focusConfirm: false,
  //     allowOutsideClick: false,
  //     confirmButtonText: "Submit",
  //     preConfirm: () => {
  //       setUsername(document.getElementById("username").value);
  //       setEmail(document.getElementById("email").value);
  //       return [
  //         document.getElementById("username").value,
  //         document.getElementById("email").value,
  //       ];
  //     },
  //   });
  // }

  const [percent, setPercent] = useState(0);
  const [buttontext, setButtonText] = useState("Invest Now");

  const getBalance = async () => {
    try {
      let USDTToken = new web3.eth.Contract(USDTABI, USDT);

      const usdtbalance = await USDTToken.methods.balanceOf(address).call();
      setUSDTBalance(
        parseFloat(ethers.utils.formatUnits(usdtbalance, 18)).toFixed(2)
      );
    } catch (error) {
      console.log(error);
    }
  };

  //calculate tokens
  const setAmountValue = (e) => {
    // e.preventDefault();

    const value = Number(e.target.value);
    setInputAmount(value);
  };

  const calculateTokens2 = () => {
    if (inputAmount) {
      const price = Number(projectData?.project.price) / 1e18;
      let tokens = Number(inputAmount) * price;
      setTokenAmount(tokens);
    } else {
      setTokenAmount("");
    }
  };

  useEffect(() => {
    calculateTokens2();
  }, [inputAmount, CryptoAddress, projectData]);

  useEffect(() => {
    // console.log(project, "project");
    const alloc = projectData?.project.allocation;
    const raised = projectData?.project.raised;
    if (alloc > 0) {
      setPercent(((raised / alloc) * 100).toFixed(2));
    }
  }, [projectData]);

  useEffect(() => {
    const amt = Number(inputAmount) * 1e18;
    const famt = amt.toLocaleString("fullwide", { useGrouping: false });
    checkAllowance(address, setisallowed, famt);
  }, [address, inputAmount]);

  // function isTimeOver(startDate, endDate) {
  //   const currentDate = new Date();
  //   const epochTime = parseInt(currentDate.getTime() / 1000); // Convert milliseconds to seconds
  //   if (epochTime >= parseInt(startDate) && epochTime <= parseInt(endDate)) {
  //     return false;
  //   } else {
  //     return true;
  //   }
  // }

  useEffect(() => {
    const fetchData = async (address) => {
      await getBalance();
      let result = await isUserInvested(address);
      setUserExist(result);
    };

    if (address) {
      fetchData(address);
    }
  }, [address]);

  // useEffect(() => {
  //   let output = isUserInvested(address);
  //   setUserExist(output);

  //   console.log("is User invested: 9090 ", output);
  // }, [address]);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     console.log("isUserExist : ", isUserExist);
  //     if (!isUserExist) {
  //       OpenFormModule();
  //     }
  //   }, 4000); // 3 seconds delay

  //   return () => clearTimeout(timer); // Clear the timeout if the component unmounts
  // }, [address, isUserExist]); // Empty dependency array ensures the effect runs only once

  return (
    <>
      <div className="bg-cover bg-vulcan bg-no-repeat bg-center mt-[-105px] md:mt-[-131px] px-3 pt-40 pb-12 lg:block xl:px-0 bg-gradient-to-r from-gray-900 to-gray-800">
        <div className="lg:grid max-w-4xl mx-auto gap-x-10 grid-cols-1 gap-y-10 md:grid-cols-1 md:gap-y-0 xl:gap-x-20 flex flex-col justify-center items-center">
          <div className="  border border-neutral-500 rounded-xl py-8 bg-black bg-opacity-30 mb-6">
            <div className="bg-transparent text-center px-4 py-2 sm:pt-3 sm:px-18">
              <div className="px-2 flex justify-between mb-2 lg:px-3 3xl:px-6">
                <div className="text-right space-y-1">
                  <div className="flex items-center gap-2">
                    <span className="relative flex h-3 w-3 ">
                      <span className="animate-ping  absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
                      <span className="relative inline-flex rounded-full h-3 w-3 bg-green-500"></span>
                    </span>
                    <span className="text-white">Private Sale is Live</span>
                  </div>
                </div>
              </div>
              <div className="flex justify-between md-4 md:my-6  lg:px-2 3xl:px-5">
                <div className="bg-gradient-to-b from-gray-900 to-gray-800 px-3 py-2 sm:px-4 sm:py-2.5 rounded-md text-left space-y-1">
                  <p className="text-[11px] sm:text-xs font-bold text-white 3xl:text-sm">
                    Amount Raised
                  </p>
                  <h2 className="text-xs sm:text-sm font-semibold text-sky-600 3xl:text-lg">
                    {projectData?.project.raised > 0
                      ? (Number(projectData?.project.raised) / 1e18).toFixed(2)
                      : 0}{" "}
                    USDT
                  </h2>
                </div>
                <div className="bg-gradient-to-b from-gray-900 to-gray-800 px-3 py-2 sm:px-4 sm:py-2.5 rounded-md text-right space-y-1">
                  <p className="text-[11px] sm:text-xs font-bold text-white 3xl:text-sm">
                    Amount Left
                  </p>
                  <h2 className="text-xs sm:text-sm font-semibold text-sky-600 3xl:text-lg capitalize">
                    <span className="text-sky-600">
                      {projectData?.project.allocation > 0
                        ? (
                            (Number(projectData?.project.allocation) -
                              Number(projectData?.project.raised)) /
                            1e18
                          ).toFixed(2)
                        : 0}{" "}
                      USDT
                    </span>
                  </h2>
                </div>
              </div>
            </div>
            <div className="overflow-hidden rounded-b-xl text-white bg-transparent shadow">
              <div className="px-4 pb-4 bg-transparent bg-opacity-70  lg:px-6 3xl:px-10 ">
                <div className="flex flex-col md:flex-row justify-around px-8 text-fuchsia-600 font-bold text-sm mt-3 md:mt-0">
                  <div>TOTAL INVESTMENT RAISED</div>
                </div>
                <div className="bg-transparent relative rounded-[2rem] mt-0 py-4 mb-2 flex justify-between items-center">
                  <div className="bg-zinc-100 h-[20px] w-full absolute bottom-0 left-0 rounded-[2rem]">
                    <span className="text-xs text-center absolute left-[40%] md:left-[45%] py-0.5 font-semibold z-20 text-sky-600">
                      {percent}% Raised
                    </span>
                    <div
                      className="py-1 w-full h-full bg-gradient-to-r from-sky-600 to-fuchsia-600 relative rounded-[2rem] w-[20%]"
                      style={{ width: `${percent}%` }}
                    ></div>
                  </div>
                </div>
                <div className="flex justify-between items-center text-white font-semibold mb-2 sm:mb-4 text-xs px-1">
                  <div className="text-left">
                    Total USDT Raised:
                    <span className="text-sky-600">
                      {parseFloat(
                        Number(projectData?.project.raised) / 1e18
                      ).toFixed(2)}{" "}
                      USDT
                    </span>
                  </div>
                </div>
                <div className="mt-4">
                  <h4 className="flex flex-row items-center gap-2 text-center text-[1rem] font-semibold leading-[30px] text-white before:h-[2px] before:w-auto before:flex-1 before:bg-gradient-to-r before:from-fuchsia-600  before:via-blue-800  before:to-[#572bf7] before:inline-block before:align-middle after:h-[2px] after:w-auto after:bg-gradient-to-r after:from-[#572bf7]  after:via-fuchsia-600  after:to-blue-800 after:inline-block after:align-middle after:flex-1">
                    Balance: {usdtBalance ? usdtBalance : 0.0} {"USDT"}
                  </h4>
                </div>
                <div className="px-2 bg-transparent lg:px-2">
                  <div className="p-2 sm:p-0 flex flex-col items-center mb-2 lg:mb-5"></div>
                  <div className="grid gap-2 md:gap-1 grid-cols-3 md:grid-cols-4 grid-rows-1 md:grid-rows-1 justify-center items-center self-center">
                    <button
                      type="button"
                      className={
                        true
                          ? "mb-2 w-full inline-flex items-center justify-center whitespace-nowrap rounded-md px-2 py-2 sm:px-1.5 sm:py-3.5 3xl:py-4 4xl:py-5 text-xs sm:text-xs font-semibold text-white leading-6 shadow-sm bg-gradient-to-r from-sky-600 to-fuchsia-600"
                          : "mb-2 w-full inline-flex items-center justify-center whitespace-nowrap rounded-md px-2 py-2 sm:px-1.5 sm:py-3.5 3xl:py-4 4xl:py-5 text-xs sm:text-xs font-semibold text-neutral-900 hover:text-white leading-6 shadow-sm bg-gradient-to-r to-fuchsia-200 from-blue-100 hover:from-sky-600 hover:to-fuchsia-600"
                      }
                    >
                      <img
                        className="w-5 pr-1"
                        alt="usdterc20"
                        src="https://nowpayments.io/images/coins/usdt.svg"
                      />
                      &nbsp;USDT
                    </button>
                  </div>

                  <div className="mt-4">
                    <h4 className="flex flex-row items-center gap-2 text-center text-sm font-semibold leading-[30px] text-white before:h-[2px] before:w-auto before:flex-1 before:bg-gradient-to-r before:from-fuchsia-600  before:via-blue-800  before:to-[#572bf7] before:inline-block before:align-middle after:h-[2px] after:w-auto after:bg-gradient-to-r after:from-[#572bf7]  after:via-fuchsia-600  after:to-blue-800 after:inline-block after:align-middle after:flex-1">
                      {"Price : $"}
                      {projectData?.project.price
                        ? Number(1e18 / projectData?.project.price)
                        : 0}{" "}
                      TOKEN{" "}
                    </h4>
                  </div>

                  <div className="flex justify-between flex-col md:flex-row">
                    <div className="mt-1.5 text-left">
                      <label
                        for="amount"
                        className="block text-white font-medium text-sm sm:text-sm"
                      >
                        Amount in USDT
                      </label>
                      <div className="relative mt-0.5 rounded-md shadow-sm">
                        <input
                          type="number"
                          onChange={setAmountValue}
                          min="0"
                          name="amount"
                          id="amount"
                          autocomplete="off"
                          aria-invalid="true"
                          aria-describedby="amount-error"
                          className="block w-full rounded-md border-0 py-2.5 sm:py-1.5 pl-4 pr-10 text-white bg-gradient-to-r from-gray-900 to-gray-800  shadow-sm ring-0 ring-inset focus:ring-2 focus:ring-inset text-sm sm:text-base font-medium sm:leading-10 text-white ring-gray-300 placeholder:text-gray-300 focus:ring-2 focus:ring-blue-800 outline-none"
                        />
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            aria-hidden="true"
                            className="h-6 w-6 text-sky-600"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className="mt-1.5 text-left pt-2 md:pt-0">
                      <label
                        for="tokens"
                        className="block text-white font-medium text-sm sm:text-sm"
                      >
                        Tokens
                      </label>
                      <div className="relative mt-0.5 rounded-md shadow-sm">
                        <input
                          type="text"
                          disabled={true}
                          name="token"
                          id="token"
                          value={tokenAmount}
                          readOnly=""
                          placeholder=""
                          className="block w-full rounded-md border-0 py-2.5 sm:py-1.5 pl-4 pr-10 text-white  bg-gradient-to-r from-gray-900 to-gray-800  shadow-sm ring-1 ring-inset ring-blue-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-800 sm:text-base font-medium sm:leading-10 outline-none"
                        />
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                          <img className="w-6" src={logo} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />

                  <button
                    onClick={() => {
                      if (inputAmount > usdtBalance) {
                        Swal.fire({
                          position: "center",
                          icon: "error",
                          title: "Insufficient USDT Amount",
                          showConfirmButton: false,
                          timer: 1500,
                        });
                        return;
                      }

                      if (true) {
                        isallowed || approveTxDone
                          ? investUSDT(
                              address,
                              inputAmount,
                              setButtonText,
                              setInputAmount
                              // username,
                              // email
                            )
                          : approveToken(
                              setLoader,
                              setApproveTxDone,
                              address,
                              setButtonText
                            );
                      }
                    }}
                    className="sm:mt-2 mb-2 w-full inline-flex items-center justify-center whitespace-nowrap border-0 rounded-md px-5 py-2 sm:px-5 sm:py-5 3xl:py-4 4xl:py-5 text-sm sm:text-md  font-semibold text-white leading-5 shadow-sm  bg-gradient-to-r from-sky-600 to-fuchsia-600 hover:bg-blue-900"
                  >
                    {buttontext}
                  </button>
                </div>
                <div className="flex justify-center text-contentclr underline"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Hero;
