import React, { useEffect, useState } from "react";
import step1 from "../images/step_1.png";
import step2 from "../images/step_2.png";
// import step3 from "../images/step_3.png";
import step3 from "../images/step-3.png";
import step4 from "../images/step_4.png";
import { useAccount, useBalance, useConnect } from "wagmi";
import { getCryptoInvestData } from "../helpers/contractHelpers";

const Explained = ({ projectData }) => {
  const { address } = useAccount();
  const balance = useBalance({ address: address });

  const [project, setProject] = useState({
    project: {
      name: "",
      allocation: 0,
      raised: 0,
      price: 0,
      vesting: "",
      startDate: 0,
      endDate: 0,
      maxInvestment: 0,
    },
    projectdata: {
      projectId: "",
      orbital7: "",
      feature: [],
      advantage: [],
      listing: [],
      streams: [],
    },
  });
  // useEffect(() => {
  //   getCryptoInvestData(setProject);
  // }, [isConnected, address]);

  // useEffect(() => {
  //   console.log("isConnected and address : ", address);
  //   const fetchData = async (address) => {
  //     await getCryptoInvestData(address, setProject);
  //   };

  //   if (address) {
  //     fetchData(address);
  //   }
  // }, [address]);

  console.log("project data : ", project);

  return (
    <>
      <div
        className="py-10 px-3 border-t border-neutral-700 xl:px-0 bg-vulcan"
        id="howtobuy"
      >
        <div className="max-w-7xl mx-auto bg-gradient-to-r from-gray-900 to-gray-800 rounded-lg px-5 py-10 sm:px-10 sm:py-16 xl:py-20">
          <div className="mx-auto grid text-center justify-center max-w-5xl w-full">
            <h1 className="font-bold text-white text-3xl md:text-5xl mb-10">
              {projectData?.project?.name
                ? projectData?.project?.name
                : "Project Name"}
            </h1>

            <div className="flex flex-wrap justify-center sm:justify-between gap-4 sm:gap-0 md:gap-4 lg:gap-8 3xl:gap-12">
              <div className="bg-gradient-to-b from-gray-900 to-gray-800 px-3 py-2 sm:px-4 sm:py-2.5 rounded-md text-left space-y-1 sm:w-60 text-center">
                <p className="text-[11px] sm:text-xs font-bold text-white 3xl:text-sm">
                  Round
                </p>
                <h2 className="text-xs sm:text-sm font-semibold text-sky-600 3xl:text-lg">
                  Private Sale
                </h2>
              </div>
              <div className="bg-gradient-to-b from-gray-900 to-gray-800 px-3 py-2 sm:px-4 sm:py-2.5 rounded-md text-right space-y-1 sm:w-60 ">
                <p className="text-[11px] sm:text-xs font-bold text-white 3xl:text-sm text-center">
                  Token Price
                </p>
                <h2 className="text-xs sm:text-sm font-semibold text-sky-600 3xl:text-lg capitalize text-center">
                  <span className="text-sky-600 ">
                    {"$"}
                    {projectData?.project?.price > 0
                      ? Number(1e18 / projectData?.project?.price)
                      : 0}
                  </span>
                </h2>
              </div>

              <div className="bg-gradient-to-b from-gray-900 to-gray-800 px-3 py-2 sm:px-4 sm:py-2.5 rounded-md text-right space-y-1 sm:w-60">
                <p className="text-[11px] sm:text-xs font-bold text-white 3xl:text-sm text-center">
                  Vesting summary
                </p>
                <h2 className="text-xs sm:text-sm font-semibold text-sky-600 3xl:text-lg capitalize text-center">
                  <span className="text-sky-600">
                    {projectData?.project?.vesting
                      ? projectData?.project?.vesting
                      : ""}
                  </span>
                </h2>
              </div>
            </div>
          </div>

          <div className="grid gap-x-20 mt-10 grid-cols-1 gap-y-10 lg:gap-x-10 lg:grid-cols-1 lg:gap-y-0 xl:gap-x-10  w-full">
            {/* <div className="w-full"> */}
            <div className="grid gap-8 sm:flex sm:gap-0 lg:grid lg:gap-12  w-5/2">
              <div className="flex flex-col ">
                <div className="flex items-center mb-2">
                  {/* <img
                    className="object-contain"
                    alt="step_2"
                    src={step3}
                    width={48}
                    height={48}
                  /> */}
                  {/* <h1 className="font-bold text-white text-base ml-2 xl:text-lg">
                    Orbital7
                  </h1> */}
                </div>
                <div>
                  <p className="text-gray-500 font-normal text-sm xl:text-base">
                    {projectData?.projectdata?.orbital7}
                  </p>
                </div>
              </div>

              <div className="flex flex-col ">
                <div className="flex items-center mb-2">
                  {/* <img
                    className="object-contain"
                    alt="step_2"
                    src={step3}
                    width={48}
                    height={48}
                  /> */}
                  {/* <h1 className="font-bold text-white text-base ml-2 xl:text-lg">
                    Tool Suite Feature
                  </h1> */}
                </div>
                <div>
                  {projectData?.projectdata?.feature && (
                    <ul className="text-gray-500 font-normal text-sm xl:text-base">
                      {projectData?.projectdata?.feature.map((item, index) => (
                        <li key={index}>- {item}</li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>

              <div className="flex flex-col ">
                <div className="flex items-center mb-2">
                  {/* <img
                    className="object-contain"
                    alt="step_2"
                    src={step3}
                    width={48}
                    height={48}
                  /> */}
                  {/* <h1 className="font-bold text-white text-base ml-2 xl:text-lg">
                    Key Product Advantage
                  </h1> */}
                </div>
                <div>
                  {projectData?.projectdata?.advantage && (
                    <ul className="text-gray-500 font-normal text-sm xl:text-base">
                      {projectData?.projectdata?.advantage.map(
                        (item, index) => (
                          <li key={index}>- {item}</li>
                        )
                      )}
                    </ul>
                  )}
                </div>
              </div>

              <div className="flex flex-col ">
                <div className="flex items-center mb-2">
                  {/* <img
                    className="object-contain"
                    alt="step_2"
                    src={step3}
                    width={48}
                    height={48}
                  /> */}
                  {/* <h1 className="font-bold text-white text-base ml-2 xl:text-lg">
                    Token Listing
                  </h1> */}
                </div>
                <div>
                  {projectData?.projectdata?.listing && (
                    <ul className="text-gray-500 font-normal text-sm xl:text-base">
                      {projectData?.projectdata?.listing.map((item, index) => (
                        <li key={index}>- {item}</li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>

              <div className="flex flex-col ">
                <div className="flex items-center mb-2">
                  {/* <img
                    className="object-contain"
                    alt="step_2"
                    src={step3}
                    width={48}
                    height={48}
                  /> */}
                  {/* <h1 className="font-bold text-white text-base ml-2 xl:text-lg">
                    Revenue Streams
                  </h1> */}
                </div>
                <div>
                  {projectData?.projectdata?.streams && (
                    <ul className="text-gray-500 font-normal text-sm xl:text-base">
                      {projectData?.projectdata?.streams.map((item, index) => (
                        <li key={index}>- {item}</li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
            {/* </div> */}
            {/* <div className="items-center grid col-span-full lg:col-span-2 "></div> */}
            {/* <div className="w-full">
              <div className="space-y-8 grid sm:space-y-0 sm:flex lg:grid lg:space-y-12 w-300 ">
                <div className="flex flex-col ">
                  <div className="flex items-center mb-2">
                    <img
                      className="object-contain"
                      alt="step_2"
                      src={step3}
                      width={48}
                      height={48}
                    />
                    <h1 className="font-bold text-white text-base ml-2 xl:text-lg">
                      Connect Wallet
                    </h1>
                  </div>
                  <div>
                    <p className="text-gray-500 font-normal text-sm xl:text-base">
                      Click on your preferred crypto icon or connect wallet and
                      choose your preferred wallet including Metamask or Trust
                      Wallet
                    </p>
                  </div>
                </div>
                <div className="flex flex-col ">
                  <div className="flex items-center mb-2">
                    <img
                      className="object-contain"
                      alt="step_2"
                      src={step3}
                      width={48}
                      height={48}
                    />
                    <h1 className="font-bold text-white text-base ml-2 xl:text-lg">
                      Connect Wallet
                    </h1>
                  </div>
                  <div>
                    <p className="text-gray-500 font-normal text-sm xl:text-base">
                      Click on your preferred crypto icon or connect wallet and
                      choose your preferred wallet including Metamask or Trust
                      Wallet
                    </p>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      {/* <div className="py-12 px-3 xl:px-0 bg-vulcan" id="roenomics">
        <div className="max-w-7xl mx-auto space-y-10">
          <div className="border-b border-gray-800 space-y-3 pb-8">
            <h1 className="font-bold text-white text-3xl">Token Overview</h1>
            <p className="font-normal text-gray-200 text-sm md:text-base lg:text-lg">
              The marketplace will be built on Binance sidechain Polygon because
              of the platform's flexibility, low transaction fees, quick
              transfer times, and excellent scaling capabilities, all while
              offering the security, interoperability, and structural benefits
              of the Binance Mainnet.
            </p>
          </div>
          <div className="justify-between grid gap-y-5 grid-cols-2 sm:grid-cols-3 lg:flex lg:gap-y-0">
            <div>
              <div className="flex">
                <p className="text-sky-600 font-bold text-base xl:text-lg">
                  $TITO Token
                </p>
                <a
                  className="text-sky-600 cursor-pointer pl-1.5"
                  href="https://etherscan.io/token/0xec6e7a7c2b70c2ec319279ecf4cce1c8717ecf59#code"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                    className="h-4 w-4"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                    ></path>
                  </svg>
                </a>
              </div>
              <div className="flex">
                <p className="text-gray-200 font-normal text-sm xl:text-lg truncate w-48">
                  0xec6e7a7c2b70c2ec319279ecf4cce1c8717ecf59
                </p>
                <span className="relative">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                    className="h-5 w-5 cursor-pointer text-sky-600 "
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"
                    ></path>
                  </svg>
                </span>
              </div>
            </div>
            <div>
              <p className="text-sky-600 font-bold text-base xl:text-lg">
                Token Name
              </p>
              <p className="text-gray-200 font-normal text-sm xl:text-lg">
                Tito
              </p>
            </div>
            <div>
              <p className="text-sky-600 font-bold text-base xl:text-lg">
                Token Symbol
              </p>
              <p className="text-gray-200 font-normal text-sm xl:text-lg">
                $TITO
              </p>
            </div>
            <div>
              <p className="text-sky-600 font-bold text-base xl:text-lg">
                Token Supply
              </p>
              <p className="text-gray-200 font-normal text-sm xl:text-lg">
                1,000,000,000
              </p>
            </div>
            <div>
              <p className="text-sky-600 font-bold text-base xl:text-lg">
                Token Type
              </p>
              <p className="text-gray-200 font-normal text-sm xl:text-lg">
                BINANCE (BEP20)
              </p>
            </div>
            <div>
              <p className="text-sky-600 font-bold text-base xl:text-lg">
                Decimals
              </p>
              <p className="text-gray-200 font-normal text-sm xl:text-lg">18</p>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};
export default Explained;
