import React from "react";
import twitter from "../images/social_logo/twitter.png";
import youtube from "../images/social_logo/youtube.png";
import telegram from "../images/social_logo/telegram.png";

const Footer = () => {
  return (
    <>
      <footer className="border-t border-neutral-700 bg-gray-900">
        <div className="mx-auto max-w-7xl overflow-hidden px-6 md:py-24 py-12">
          <p className="md:text-center text-sm  text-gray-500 max-w-2xl mx-auto">
            <strong>Disclaimer:</strong>
            Cryptocurrency may be unregulated in your jurisdiction. The value of
            cryptocurrencies may go down as well as up. Profits may be subject
            to capital gains or other taxes application in your jurisdiction.
          </p>
          <p className="md:text-center text-sm  text-gray-500 mt-6">
            Copyright © 2024 Launchpad Deals. All Rights Reserved.
          </p>
        </div>
      </footer>
    </>
  );
};
export default Footer;
